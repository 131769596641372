<template>
  <div>
    <query-form
      ref="queryRef"
      :query-form.sync="queryUser"
      :form="form"
    >
      <template v-slot:buttons>
        <b-button
          variant="primary"
          class="mr-1"
          @click="search"
        >Search</b-button>
        <b-button
          variant="secondary"
          class="mr-1"
          @click="reset"
        >Reset</b-button>
        <b-button
          v-hasBtn="`merchant_user_management`"
          variant="success"
          @click="addUser"
        >+New</b-button>
      </template>
    </query-form>
    <b-card>
      <b-table
        ref="refUserListTable"
        :items="userList"
        responsive
        :fields="tableColumns"
        primary-key="userId"
        show-empty
        empty-text="No matching records found"
        class="position-relative"
      >
        <!-- Column: user_id -->
        <template #cell(user_id)="data">
          <div style="width: 150px">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: user_name -->
        <template #cell(user_name)="data">
          <div style="width: 150px">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: role_meta -->
        <template #cell(role_meta)="data">
          <div style="width: 150px">
            {{ data.value.role_name }}
          </div>
        </template>
        <!-- Column: user_status -->
        <template #cell(user_status)="data">
          {{ data.value === 1 ? 'On' : 'Off' }}
        </template>
        <!-- Column: email -->
        <template #cell(email)="data">
          <div style="width: 150px">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: created_at_ms -->
        <template #cell(created_at_ms)="data">
          <span class="text-nowrap"> {{ updateDate(data.value/1000, timeZone) }}</span>
        </template>
        <!-- Column: operations -->
        <template
          #cell(operations)="data"
        >
          <b-button
            v-hasBtn="`merchant_user_management`"
            variant="primary"
            class="btn-icon mr-1"
            size="sm"
            @click="resetPassword(data.item)"
          >
            <!-- UnlockIcon -->
            <feather-icon
              icon="LockIcon"
            />
          </b-button>
          <b-button
            v-hasBtn="`merchant_user_management`"
            variant="secondary"
            class="btn-icon"
            size="sm"
            @click="editUser(data.item)"
          >
            <feather-icon
              icon="Edit3Icon"
            />
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted mr-1"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- New User -->
    <b-modal
      ref="newUserModel"
      :title="newUserTitle"
      ok-title="Save"
      cancel-variant="outline-secondary"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <b-overlay
        :show="addUserLoading"
      >
        <validation-observer
          ref="addUserForm"
        >
          <b-form class="mt-1">
            <b-form-group
              label="User Name"
              label-for="username"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="User Name"
                vid="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="userNameModel"
                  placeholder="User Name"
                  autocomplete="off"
                  :formatter="value => sliceStr(value, 10)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Email"
              label-for="email"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="emailModel"
                  placeholder="Email"
                  autocomplete="off"
                  :formatter="value => sliceStr(value, 75)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
              label-cols-md="3"
              class="role-model-input"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                vid="role"
                rules="required"
              >
                <v-select
                  id="role"
                  v-model="roleModel"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleList"
                  autocomplete="off"
                  :clearable="false"
                  label="role_name"
                  :reduce="value => value"
                  placeholder="role"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Details"
              label-for="details"
              label-cols-md="3"
            >
              <b-form-textarea
                id="details"
                v-model="detailsModel"
                placeholder="Details"
                autocomplete="off"
                rows="3"
                :formatter="value => sliceStr(value, 300)"
              />
            </b-form-group>
            <b-form-group
              label="Status"
              label-for="status"
              label-cols-md="3"
            >
              <b-form-checkbox
                id="statusModel"
                v-model="statusModel"
                style="margin-top: 6px;"
                :checked="statusModel"
                name="check-button"
                switch
                inline
              />
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!-- reset password -->
    <b-modal
      ref="resetPasswordModel"
      :title="passwordModelTitle"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      centered
      hide-footer
      @ok="handleResetOk"
    >
      <div v-if="isResetFirst">
        <p class="text-center mt-2">
          Are you sure you wish to reset {{ userNameReset }}'s password?
        </p>
        <div class="text-center mt-2 mb-2">
          <b-button
            variant="outline-primary"
            class="mr-2"
            @click="handleCancelOk"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="handleResetOk"
          >
            Reset
          </b-button>
        </div>
      </div>
      <div v-if="!isResetFirst">
        <p class="text-center mt-2">
          The new password for the user {{ `"${userNameReset}"` }} is below
        </p>
        <div class="text-center mt-2 mb-2 mr-5 ml-5">
          <b-input-group>
            <b-form-input
              v-model="passwordValue"
              class="text-center"
              :disabled="true"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="copyPassword"
              >
                <feather-icon icon="CopyIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
    </b-modal>

  </div>
</template>
<script>
import {
  defineComponent, ref, computed, getCurrentInstance, watch, onMounted, onBeforeMount,
} from '@vue/composition-api'
import {
  BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, BFormTextarea, BFormCheckbox, BFormGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { updateDate, sliceStr } from '@/libs/utils'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import copy from 'copy-to-clipboard'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userManagement from './userManagement'
import newUser from './newUser'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const { $store } = proxy
    const queryUser = ref({})
    const perPage = ref(10)
    const currentPage = ref(1)
    const totalList = ref(0)
    const refUserListTable = ref(null)
    const userList = ref([])
    const tableColumns = [
      { key: 'user_id', label: 'user id' },
      { key: 'user_name', label: 'user name' },
      { key: 'role_meta', label: 'role' },
      { key: 'user_status', label: 'status' },
      { key: 'email', label: 'email' },
      { key: 'created_at_ms', label: 'creation date' },
      { key: 'operations', label: 'operations' },
    ]
    const dataMeta = computed(() => {
      const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalList.value,
        totalList: totalList.value,
      }
    })
    const search = () => {
      const params = {
        user_name: queryUser.value.userName,
        page_size: perPage.value,
        page_no: currentPage.value,
      }
      if (queryUser.value.roleBean && queryUser.value.roleBean.role_id !== '') {
        params.role_id = queryUser.value.roleBean.role_id
      }
      if (queryUser.value.status && queryUser.value.status.value !== '') {
        params.status = queryUser.value.status.value
      }
      setTimeout(async () => {
        proxy.$showLoading()
        const { data } = await proxy.$api.getAccountList(params)
        proxy.$hideLoading()
        if (data.code === 200) {
          const { list, pagination } = data.data
          userList.value = list
          totalList.value = pagination.total_count
        }
      }, 0)
    }

    const reset = () => {
      queryUser.value = {}
      proxy.$refs.queryRef.reset()
    }
    const changePagination = page => {
      currentPage.value = page
    }

    const { a } = userManagement()

    // 新增用户
    const {
      newUserTitle,
      roleList,
      addUserLoading,
      userNameModel,
      emailModel,
      roleModel,
      detailsModel,
      statusModel,
      currentUserId,
      resetModal,
      getRoleList,
      handleSubmit,
    } = newUser()
    const addUser = () => {
      currentUserId.value = null
      newUserTitle.value = 'New User'
      proxy.$refs.newUserModel.show()
    }
    // model
    const editUser = row => {
      currentUserId.value = row.user_id
      proxy.$refs.newUserModel.show()
      newUserTitle.value = 'Edit User'
      proxy.$nextTick(() => {
        userNameModel.value = row.user_name
        emailModel.value = row.email
        // eslint-disable-next-line prefer-destructuring
        if (row.role_meta) {
          roleModel.value = { role_id: row.role_meta.role_id, role_name: row.role_meta.role_name }
        }
        detailsModel.value = row.description
        statusModel.value = row.user_status === 1
      })
    }

    const userNameReset = ref('')
    const isResetFirst = ref(true)
    const passwordValue = ref('')
    const passwordModelTitle = ref('')
    const handleOk = bvModalEvt => {
      bvModalEvt.preventDefault()
      handleSubmit(({ userId, userName, password }) => {
        currentPage.value = 1
        search()
        if (!userId) { // 新增时显示弹框展示密码
          passwordModelTitle.value = 'Password'
          proxy.$refs.resetPasswordModel.show()
          isResetFirst.value = false
          userNameReset.value = userName
          passwordValue.value = password
        }
      })
    }
    // reset password
    const resetPassword = row => {
      passwordModelTitle.value = 'Reset Password'
      isResetFirst.value = true
      userNameReset.value = row.user_name
      currentUserId.value = row.user_id
      proxy.$refs.resetPasswordModel.show()
    }
    const handleCancelOk = () => {
      proxy.$nextTick(() => {
        proxy.$refs.resetPasswordModel.hide()
      })
    }
    const handleResetOk = async bvModalEvt => {
      bvModalEvt.preventDefault()
      const params = { platform: 2, user_id: currentUserId.value }
      const response = await proxy.$api.resetPasswordSingle(params)
      const { code, message, data } = response.data
      if (code === 200) {
        proxy.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: 'Reset Success',
            variant: 'success',
          },
        }, {
          position: 'top-center',
        })
        isResetFirst.value = false
        passwordValue.value = data.password
      } else {
        proxy.$toast({
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'BellIcon',
            text: `${message}`,
            variant: 'danger',
          },
        }, {
          position: 'top-center',
        })
      }
    }
    const copyPassword = () => {
      copy(passwordValue.value)
      proxy.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notification',
          icon: 'BellIcon',
          text: 'Copy Success',
          variant: 'success',
        },
      }, {
        position: 'top-center',
      })
    }

    watch([currentPage, perPage], () => { search() })

    onMounted(() => { search() })

    const timeZone = computed(() => $store.state.merchant.timeZone, { immediate: true, deep: true })

    const form = ref([])
    onBeforeMount(async () => {
      await getRoleList()
      form.value = [
        {
          label: 'User Name', itype: 'input', imodel: 'userName', placeholder: 'User Name',
        },
        {
          label: 'Role Name', itype: 'select', imodel: 'roleBean', placeholder: 'Role Name', Options: [{ role_id: '', role_name: 'All' }].concat(roleList.value), labelKey: 'role_name', labelValue: 'role_id',
        },
        // 1:启用 2:禁用
        {
          label: 'Status', itype: 'select', imodel: 'status', placeholder: 'Status', Options: [{ label: 'All', value: '' }, { label: 'On', value: 1 }, { label: 'Off', value: 2 }], labelKey: 'label', labelValue: 'value',
        },
      ]
    })

    return {
      form,
      queryUser,
      perPage,
      currentPage,
      totalList,
      refUserListTable,
      userList,
      tableColumns,
      dataMeta,
      userNameReset,
      // model
      newUserTitle,
      roleList,
      addUserLoading,
      userNameModel,
      emailModel,
      roleModel,
      detailsModel,
      statusModel,
      resetModal,
      handleOk,
      isResetFirst,
      passwordValue,
      passwordModelTitle,
      // func
      search,
      reset,
      addUser,
      editUser,
      changePagination,
      resetPassword,
      handleCancelOk,
      handleResetOk,
      copyPassword,

      updateDate,
      sliceStr,

      required,
      email,

      timeZone,
    }
  },
  components: {
    BCard, BForm, BRow, BCol, BInputGroup, BFormInput, BButton, BTable, BPagination, vSelect, BFormTextarea, BFormCheckbox, BFormGroup, BInputGroupAppend, BOverlay, ValidationProvider, ValidationObserver,
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.role-model-input {
  #vs1__combobox {
    height: 38px!important;
    input::placeholder {
      color: #b9b9c3;
    }
  }

}

</style>
